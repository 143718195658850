import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { Recipe } from '../types';
import Layout from '../components/layout/layout';
import { H1, P } from '../components';

interface BeerProps {
  data: {
    recipe: Recipe;
  };
}

const BeerDetails: React.FC<BeerProps> = ({ data: { recipe } }) => {
  return (
    <Layout title={`${recipe.title} | Recipes`} description={recipe.descriptionSEO}>
      <div className="w-full pt-32 md:pt-40 lg:pt-60 px-10 flex flex-col items-center justify-center gap-10">
        <div className="w-full flex flex-col lg:flex-row items-start justify-evenly">
          <div className="w-full block lg:hidden mb-5">
            <GatsbyImage image={recipe.image.gatsbyImageData} alt={recipe.image.title} />
          </div>
          <div>
            <H1>{recipe.title}</H1>
            <P>{recipe.description.description}</P>
            <P>
              <b>Season:</b>
              {recipe.seasons.join(', ')}
            </P>
            <P>
              <b>Serves:</b>
              {recipe.serves}
            </P>
            <P>
              <b>Ingredients:</b>
              <ul>
                {recipe.ingredients.map((ingredient, index) => (
                  <li key={index}>{ingredient}</li>
                ))}
              </ul>
            </P>
          </div>
          <div className="w-full lg:w-1/3">
            <div className="w-full hidden lg:block mb-5">
              <GatsbyImage image={recipe.image.gatsbyImageData} alt={recipe.image.title} />
            </div>
            <P>
              <b>Procedure:</b>
              <ul>
                {recipe.procedures.map((procedure, index) => (
                  <li key={index}>
                    <b>{`Step ${index + 1}: `}</b>
                    {procedure}
                  </li>
                ))}
              </ul>
            </P>
          </div>
        </div>
        <P noSpacing>
          <em>Bon appétit!</em>
        </P>
      </div>
    </Layout>
  );
};

export default BeerDetails;

export const query = graphql`
  query ($slug: String!, $language: String!) {
    recipe: contentfulRecipe(slug: { eq: $slug }, node_locale: { eq: $language }) {
      course
      title
      slug
      serves
      seasons
      title
      procedures
      pairedWith {
        slug
      }
      ingredients
      image {
        title
        gatsbyImageData
      }
      descriptionSEO
      description {
        description
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
